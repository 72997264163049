









































import {
  defineComponent, ref, onMounted,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'PrivacyPolicy',
  setup() {
    const loading = ref(true);
    onMounted(() => loading.value = false);

    onMounted(() => {
      const content = document.querySelector('#content');
      const links = document.querySelector('#links');
      const h3Elements = content.querySelectorAll('h3');

      h3Elements.forEach((h3) => {
        const a = document.createElement('a');

        a.className = 'link';
        a.href = `#${h3.id}`;
        a.textContent = h3.textContent;
        links.append(a);
      });
    });

    return {
      loading,
    };
  },
  head() {
    return {
      title: this.$t('Privacy Policy').toString(),
    };
  },
});
